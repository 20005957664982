html {
	height: 100%;
	body {
		margin: 0;
		background-color: "#F8F6F6 ";
		font-family: -apple-system, BlinkMacSystemFont, "Roboto", "Helvetica", "Arial", sans-serif;
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
		height: 100%;
		#root {
			height: 100%;
		}
	}

	code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
		monospace;
	}
}
